<template>
  <div>
    <QrCodeVue
      :value="value"
      :size="size"
      :level="_level"
      :background="background"
      :foreground="_foreground"
      :renderAs="_renderAs"
      :className="className"
    />
    <slot></slot>
  </div>
</template>

<script>
import QrCodeVue from "qrcode.vue";

export default {
  name: "qr-code",
  components: {
    QrCodeVue,
  },
  props: {
    value: {
      type: String,
      default: "http://google.fr",
    },
    size: {
      type: Number,
      default: 300,
    },
    quality: {
      type: String,
      default: "H",
    },
    background: {
      type: String,
      default: "white",
    },
    color: {
      type: String,
      default: "black",
    },
    image: {
      type: String,
      default: "svg",
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    _level() {
      switch (this.quality || "") {
        case "L":
          return this.quality;
        case "M":
          return this.quality;
        case "Q":
          return this.quality;
        case "H":
          return this.quality;
        default:
          return "H";
      }
    },
    _renderAs() {
      switch (this.image || "") {
        case "png":
          return "canvas";
        case "svg":
          return "svg";
        default:
          return "svg";
      }
    },
    _foreground() {
      return this.color || "black";
    },
  },
};
</script>
